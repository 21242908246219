import React, { useEffect, useState, useCallback, useRef } from "react";
import { useFormikContext } from "formik";
import FormComponentStateWrapper from "./FormComponentStateWrapper";
import { CircularProgress } from "@material-ui/core";
import createFormComponent from "./createFormComponent";

const VALIDATION_DELAY = 1000;
export const ComponentState = {
  UNSELECTED: "unselected",
  VALIDATING: "validating",
  SUCCESS: "success",
  ERROR: "error",
  BLOCKED: "blocked",
};

const FormComponentWrapper = ({
  state,
  componentConfig,
  stateChangeCallback = () => {},
  disabled: enforceDisabled = false,
}) => {
  const [componentState, setComponentState] = useState(ComponentState.BLOCKED);
  const [transcription] = useState(null);
  const [optionsExpanded, setOptionsExpanded] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [validated, setValidated] = useState(false);

  const { validateField, errors } = useFormikContext();

  const previousComponentStateRef = useRef(null);

  const disabled =
    enforceDisabled ||
    (componentState !== ComponentState.SUCCESS &&
      componentState !== ComponentState.ERROR);

  const componentProps = {
    ...componentConfig.component,
    disabled,
    open: optionsExpanded,
  };

  const currentError = (errors && errors[componentProps.name]) || null;

  useEffect(() => {
    setErrorMessage(null);
    setComponentState(state);
  }, [state]);

  useEffect(() => {
    if (!componentState || !componentProps) return;
    handleComponentState();
    stateChangeCallback(
      componentProps,
      componentState,
      previousComponentStateRef.current
    );
    previousComponentStateRef.current = componentState;
  }, [componentState]);

  const handleComponentState = useCallback(async () => {
    if (!componentProps) return;
    switch (componentState) {
      case ComponentState.BLOCKED:
        setOptionsExpanded(false);
        break;
      case ComponentState.UNSELECTED:
        setOptionsExpanded(null);
        break;

      case ComponentState.VALIDATING:
        await handleValidation();
        setOptionsExpanded(false);
        break;

      case ComponentState.SUCCESS:
        setErrorMessage(null);
        setOptionsExpanded(null);
        break;

      default:
        console.debug("State: ", componentState);
        setOptionsExpanded(null);
        break;
    }
  }, [componentState]);

  const handleFormikValidation = useCallback(() => {
    if (validated) return;
    setValidated(true);
    if (componentState === ComponentState.VALIDATING && currentError) {
      setComponentState(ComponentState.ERROR);
      setErrorMessage(currentError);
    } else if (componentState === ComponentState.VALIDATING && errors) {
      if (componentProps.multiple) {
        setComponentState(ComponentState.COMPLEMENTING);
      } else {
        setComponentState(ComponentState.SUCCESS);
      }
    }
  }, [errors, componentState, validated]);

  function handleValidation() {
    validateField(componentProps.name);
    // trigger validation
    setTimeout(() => {
      handleFormikValidation();
    }, VALIDATION_DELAY);
  }

  return (
    <FormComponentStateWrapper
      componentState={componentState}
      transcription={transcription}
      errorMessage={errorMessage}
    >
      {componentProps ? (
        createFormComponent(componentProps.type, componentProps)
      ) : (
        <CircularProgress />
      )}
    </FormComponentStateWrapper>
  );
};

export default FormComponentWrapper;
