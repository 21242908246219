export const hasNonNullValue = (obj) =>
  Object.values(obj).some((value) => value !== null);

export const hasNonEmptyValue = (obj) =>
  Object.values(obj).some(
    (value) =>
      value !== "" &&
      value !== null &&
      value !== undefined &&
      value.length !== 0 &&
      Object.keys(obj).length !== 0
  );

export function areValuesEqual(obj1, obj2) {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
}
