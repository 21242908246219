// NavigationContext.js
import React, { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const NavigationContext = createContext(
  createContext({
    isModalOpen: false,
    setIsModalOpen: () => {
      console.warn("no navigation provider");
    },
    handleNavigate: () => {},
    navigateToLastPath: () => {},
    confirmNavigation: () => {},
  })
);

const NavigationProvider = ({ children }) => {
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [intendedPath, setIntendedPath] = useState(null);
  const [isLateralMenuOpen, setIsLateralMenuOpen] = useState(false);
  const [lastPath, setLastPath] = useState(null);

  useEffect(() => {
    return () => {
      console.log("Navigation Context: cleanup");
    };
  }, []);

  const handleNavigate = (path) => {
    const currentPath = window.location.pathname;
    const mustConfirmNavigation = Boolean(
      localStorage.getItem("unsavedFormData")
    );
    // if (mustConfirmNavigation) {
    if (mustConfirmNavigation && currentPath.indexOf("/template/") !== -1) {
      // TODO: remove this hardcoded part: currentPath !== "/"
      setIntendedPath(path);
      setIsModalOpen(true);
    } else {
      navigate(path);
      setLastPath(currentPath);
    }
  };

  const navigateToLastPath = (except = []) => {
    if (except && except.indexOf(lastPath) !== -1) {
      return;
    }
    handleNavigate(lastPath);
  };

  const confirmNavigation = () => {
    if (intendedPath) {
      navigate(intendedPath);
      setIsModalOpen(false);
    }
  };

  return (
    <NavigationContext.Provider
      value={{
        handleNavigate,
        navigateToLastPath,
        currentPath: window.location.pathname,
        confirmNavigation,
        isModalOpen,
        setIsModalOpen,
        isLateralMenuOpen,
        setIsLateralMenuOpen,
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
};

export { NavigationContext, NavigationProvider };
