import { useEffect } from "react";
import isEqual from "lodash.isequal";
import usePrevious from "./usePrevious";

const useDeepEffect = (callback, dependencies) => {
  const previousDependencies = usePrevious(dependencies);

  useEffect(() => {
    if (!isEqual(previousDependencies, dependencies)) {
      callback();
    }
  }, [dependencies, callback, previousDependencies]);
};

export default useDeepEffect;
