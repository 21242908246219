import logoGeneralitat from "./../assets/images/logo-generalitat.png";
import logoDepartament from "./../assets/images/logo-departament.png";
import logoErvc from "./../assets/images/icon-ervc.svg";
import faviconGeneralitat from "./../assets/images/favicon-generalitat.ico";

// !! Temporary solution. Used o

export let favicon = null;
export let loginLogo1 = null;
export let loginLogo2 = null;
export let headerLogo = null;
export let lateralMenuLogo = null;

const setFavicon = (id) => {
  const element = document.getElementById(id);
  if (!favicon || !element) {
    return;
  }
  element.setAttribute("href", favicon);
};

const setFavicons = () => {
  const ids = ["favicon", "apple-touch-favicon"];
  ids.forEach((id) => setFavicon(id));
};

const setSkinStyle = () => {
  switch (process.env.REACT_APP_SKIN) {
    case "generalitat":
      favicon = faviconGeneralitat;
      loginLogo1 = logoDepartament;
      loginLogo2 = logoErvc;
      headerLogo = logoGeneralitat;
      lateralMenuLogo = logoGeneralitat;
      break;
    default:
      console.log("No skin selected");
  }
};

export const applySkin = () => {
  setSkinStyle();
  setFavicons();
};
