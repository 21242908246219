import React from "react";
import { useField } from "formik";
import { TextField } from "@material-ui/core";

const NumericFieldComponent = ({
  name,
  label,
  minValue,
  maxValue,
  disabled = false,
}) => {
  const [field, meta] = useField(name);

  return (
    <TextField
      {...field}
      label={label}
      type="number"
      inputProps={{
        min: minValue !== undefined ? minValue : null,
        max: maxValue !== undefined ? maxValue : null,
      }}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
      fullWidth
      disabled={disabled}
    />
  );
};

export default NumericFieldComponent;
