import React from "react";
import TextFieldComponent from "./FormTextField";
import { Button, Typography } from "@material-ui/core";
import { useFormikContext } from "formik";
import useDeepEffect from "../../../hooks/useDeepEffect";
import { useTranslation } from "react-i18next";

const DynamicTextFields = ({
  name,
  label,
  disabled = false,
  onFieldAdd,
  required = false,
}) => {
  const { setFieldValue, values } = useFormikContext();
  const { t } = useTranslation();

  const currentValue = values[name];
  const subValues = Object.keys(values)
    .filter((key) => key.startsWith(name) && key !== name)
    .map((key) => values[key]);
  const textFields = Array.isArray(currentValue)
    ? currentValue.map((value, idx) => ({
        name: `${name}${idx}`,
        label: "",
        value: value || null,
      }))
    : [];

  const addTextField = () => {
    setFieldValue(name, [...currentValue, null]);
    if (onFieldAdd) {
      onFieldAdd();
    }
  };

  useDeepEffect(() => {
    const newCurrentValue = subValues.map((subValue) => subValue || null);
    setFieldValue(name, newCurrentValue);
  }, [subValues]);

  useDeepEffect(() => {
    textFields.forEach((field) => {
      setFieldValue(field.name, field.value);
    });
    setFieldValue(
      name,
      textFields.map((field) => field.value)
    );
  }, [textFields]);

  return (
    <div>
      <Typography className={disabled ? "text-disabled" : ""}>
        {label}
      </Typography>
      {textFields.map((field, idx) => (
        <TextFieldComponent
          key={idx}
          name={field.name}
          label={field.label}
          disabled={disabled}
          required={required}
        />
      ))}
      {!disabled ? (
        <Button onClick={addTextField} onKeyDown={() => {}} disabled={disabled}>
          {t("FORM.BUTTON.ADD")}
        </Button>
      ) : null}
    </div>
  );
};

export default DynamicTextFields;
