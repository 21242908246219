function getLongestString(strings) {
  return strings.reduce((prev, current) =>
    prev.length > current.length ? prev : current
  );
}

function normalizeString(string) {
  if (!string) return string;
  return string
    .toLowerCase()
    .normalize("NFD")
    .replace(/^([0-9]+\s*)*-\s*/g, "")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/[^a-z0-9 ]/g, "");
}

export { getLongestString, normalizeString };
