import React, { useContext, useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./HomePage";
import SpeechForm from "./components/forms/SpeechForm";
import Header from "./components/Header";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Footer from "./components/Footer";
import {
  NavigationProvider,
  NavigationContext,
} from "./contexts/NavigationContext";
import { UserContext, UserProvider } from "./contexts/UserContext";
import ConfirmModal from "./components/ConfirmModal";
import LateralMenu from "./components/menu/LateralMenu";
import LoginPage from "./components/LoginPage";
import { checkUser } from "./utils/apiMethods";
import StatusType from "./typification/StatusType";
import { applySkin } from "./utils/lookAndFeel";

const primaryColor = getComputedStyle(document.documentElement)
  .getPropertyValue("--primary-color")
  .trim();
const secondaryColor = getComputedStyle(document.documentElement)
  .getPropertyValue("--secondary-color")
  .trim();

const customTheme = createTheme({
  palette: {
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: secondaryColor,
    },
  },
});

const App = () => {
  useEffect(() => {
    applySkin();
  }, []);

  return (
    <ThemeProvider theme={customTheme}>
      <div className="root">
        <Router>
          <UserProvider>
            <NavigationProvider>
              <AppContent />
            </NavigationProvider>
          </UserProvider>
        </Router>
      </div>
    </ThemeProvider>
  );
};

const ProtectedRoute = ({ children }) => {
  const { user } = useContext(UserContext);
  const { handleNavigate } = useContext(NavigationContext);

  useEffect(() => {
    if (!user) {
      handleNavigate("/login");
    }
  }, [user, handleNavigate]);

  return user ? children : null;
};

const AppContent = () => {
  const {
    isModalOpen,
    currentPath,
    setIsModalOpen,
    confirmNavigation,
    isLateralMenuOpen,
    setIsLateralMenuOpen,
  } = useContext(NavigationContext);

  const { setUser } = useContext(UserContext);

  const { handleNavigate, navigateToLastPath } = useContext(NavigationContext);

  useEffect(() => {
    if (currentPath !== "/login") {
      checkUserAuthentication();
    }
  }, []);

  const checkUserAuthentication = async () => {
    try {
      const response = await checkUser();
      if (response.status_code === 200) {
        setUser(response.data.user);
        navigateToLastPath(["/login"]);
      } else {
        throw new Error("User not authenticated");
      }
    } catch (error) {
      setUser(null);
      handleNavigate("/login");
    }
  };

  return (
    <>
      <Header />
      {isLateralMenuOpen && (
        <LateralMenu onClose={() => setIsLateralMenuOpen(false)} />
      )}
      <div className="content">
        <Routes>
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <HomePage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/template/:formId"
            element={
              <ProtectedRoute>
                <SpeechForm />
              </ProtectedRoute>
            }
          />
          <Route
            path="/form/:formId"
            element={
              <ProtectedRoute>
                <SpeechForm status={StatusType.BLOCKED} />
              </ProtectedRoute>
            }
          />
          <Route path="/login" element={<LoginPage />} />
        </Routes>
      </div>
      <Footer />
      <ConfirmModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={confirmNavigation}
      />
    </>
  );
};

export default App;
