import React, { createContext, useState } from "react";
import { logoutUser } from "../utils/apiMethods";
import { useNavigate } from "react-router-dom";

const UserContext = createContext();

const UserProvider = ({ children }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);

  const logout = async () => {
    try {
      navigate("/login");
      const response = await logoutUser();
      if (response.status_code === 200) {
        setUser(null);
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
        localStorage.removeItem("userData");
        localStorage.removeItem("unsavedFormData");
      } else {
        // Handle error
        throw new Error("Logout failed");
      }
    } catch (error) {
      // Handle error
      throw error;
    }
  };

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        logout,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
