// components/ConfirmModal.jsx
import React, { useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";

const ConfirmModal = ({ open, onClose, onConfirm }) => {
  const { t } = useTranslation();

  useEffect(() => {
    console.log("ConfirmModal: open", open);
  }, [open]);
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t("FORM.MODAL.TITLE")}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t("FORM.MODAL.UNSAVED")}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} onKeyDown={onClose} color="primary">
          {t("FORM.MODAL.CANCEL")}
        </Button>
        <Button
          onClick={() => {
            onConfirm();
            onClose();
          }}
          onKeyDown={() => {
            onConfirm();
            onClose();
          }}
          color="primary"
        >
          {t("FORM.MODAL.CLOSE")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmModal;
