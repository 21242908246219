import React from "react";
import { Box, Tooltip, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { alpha } from "@material-ui/core/styles/colorManipulator";
import { ComponentState } from "./FormComponentWrapper";

const useStyles = makeStyles((theme) => ({
  unselected: {
    backgroundColor: theme.palette.background.default,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
  },
  reading: {
    backgroundColor: theme.palette.grey.light,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
  },
  recording: {
    backgroundColor: theme.palette.grey.light,
    animation: `$pulsate 1.5s infinite`,
    border: `1px solid ${theme.palette.error.main}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
  },
  "@keyframes pulsate": {
    "0%": {
      boxShadow: `0 0 0 0 ${alpha(theme.palette.warning.main, 0.5)}`,
    },
    "30%": {
      boxShadow: `0 0 0 20px ${alpha(theme.palette.background.default, 0)}`,
    },
    "60%": {
      boxShadow: `0 0 0 0 ${alpha(theme.palette.background.default, 0)}`,
    },
    "90%": {
      boxShadow: `0 0 0 10px ${alpha(theme.palette.background.default, 0)}`,
    },
    "100%": {
      boxShadow: `0 0 0 0 ${alpha(theme.palette.background.default, 0)}`,
    },
  },
  validating: {
    backgroundColor: theme.palette.background.default,
    // backgroundColor: theme.palette.info.light,
    border: `1px solid ${theme.palette.info.main}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  complementing: {
    backgroundColor: theme.palette.grey.light,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
  },
  spinnerProgress: {
    marginLeft: theme.spacing(1),
    position: "absolute",
    alignSelf: "center",
  },
  success: {
    backgroundColor: theme.palette.grey.light,
    // backgroundColor: theme.palette.success.light,
    border: `1px solid ${theme.palette.divider}`,
    // border: `1px solid ${theme.palette.success.main}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
  },
  retrying: {
    backgroundColor: theme.palette.grey.light,
    // backgroundColor: theme.palette.warning.light,
    border: `1px solid ${theme.palette.divider}`,
    // border: `1px solid ${theme.palette.warning.main}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
  },
  error: {
    backgroundColor: theme.palette.grey.light,
    // backgroundColor: theme.palette.error.light,
    border: `1px solid ${theme.palette.divider}`,
    // border: `1px solid ${theme.palette.error.main}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
  },
  blocked: {
    backgroundColor: theme.palette.grey.light,
    // border: `1px solid ${theme.palette.error.main}`,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    pointerEvents: "none", // Prevents user interactions
    opacity: 0.5, // Gives a faded look
  },
}));

const FormComponentStateWrapper = ({
  componentState,
  errorMessage,
  children,
}) => {
  const classes = useStyles();

  return (
    <Tooltip title={errorMessage || ""} placement="left-start" role="alert">
      <Box className={classes[componentState]}>
        {componentState === ComponentState.VALIDATING ? (
          <div>
            {children}
            <CircularProgress className={classes.spinnerProgress} size={24} />
          </div>
        ) : (
          children
        )}
      </Box>
    </Tooltip>
  );
};

export default FormComponentStateWrapper;
