function joinArrayElementsIntoStringSequence(
  array,
  lastSeparator = "and",
  separator = ","
) {
  if (array.length === 0) {
    return "";
  } else if (array.length === 1) {
    return array[0];
  } else {
    let lastElement = array.pop();
    return array.join(`${separator} `) + ` ${lastSeparator} ` + lastElement;
  }
}

export { joinArrayElementsIntoStringSequence };
