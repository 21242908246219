import { t } from "i18next";
import RecorderStateType from "../components/forms/typification/RecorderStateType";
import i18n from "../i18n";

const DEFAULT_MAX_SPEECH_TIME = null;
const CHUNK_DURATION = 1000;
const DEFAULT_CONNECTING_TIMEOUT = 8000;

class SpeechToFormStreamer {
  // Public methods:
  constructor(
    onFormReceive,
    onTranscriptionReceive,
    handleErrors,
    recorderStateRef,
    maxSpeechTime = DEFAULT_MAX_SPEECH_TIME,
    startMessage = "START",
    stopMessage = "EOS",
    connectingTimeoutMillis = DEFAULT_CONNECTING_TIMEOUT
  ) {
    // super();
    this._onFormReceive = onFormReceive;
    this._onTranscriptionReceive = onTranscriptionReceive;
    this._handleErrors = handleErrors;
    this._recorderStateRef = recorderStateRef;
    this._maxSpeechTime = maxSpeechTime;
    this._startMessage = startMessage;
    this._stopMessage = stopMessage;
    this._connectingTimeoutMillis = connectingTimeoutMillis;
    this._webSocket = null;
    this._mediaRecorder = null;
    this._initialFormState = null;
    this._connectingTimeout = null;
  }

  isStreaming() {
    return this.isWebSocketOpen() && this.isRecording() ? true : false;
  }

  isWebSocketOpen() {
    return this._webSocket && this._webSocket.readyState === WebSocket.OPEN
      ? true
      : false;
  }

  isRecording() {
    return this._mediaRecorder && this._mediaRecorder.state === "recording"
      ? true
      : false;
  }

  start() {
    this._setupWebSocket();
    this._setupMediaRecorder();
  }

  close() {
    if (this._mediaRecorder && this._mediaRecorder.state !== "inactive") {
      this._stopMediaRecorder();
    }
    if (this._webSocket && this._webSocket.readyState === WebSocket.OPEN) {
      this._webSocket.send(this._stopMessage);
      this._webSocket.close();
    }
  }

  send(data) {
    switch (this._webSocket.readyState) {
      case WebSocket.OPEN:
        this._webSocket.send(data);
        break;
      case WebSocket.CLOSING:
        console.debug("WebSocket is closing.");
        console.warn("Could not send data. WebSocket is closing.");
        break;
      default:
        console.error("WebSocket is not open");
        this._handleErrors(t("FORM.WEBSOCKET.NOTOPEN"));
        break;
    }
  }

  sendFormState(formState) {
    const message = {
      type: "form",
      payload: formState,
    };
    this.send(JSON.stringify(message));
  }

  sendLanguage() {
    const message = {
      type: "language",
      payload: i18n.language,
    };
    this.send(JSON.stringify(message));
  }

  setInitialFormState(formState) {
    this._initialFormState = formState;
  }

  // Private methods
  async _setupMediaRecorder() {
    let stream;
    try {
      stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: false,
      });
    } catch (e) {
      console.error("No microphone allowed.");
      this._handleErrors(t("FORM.MICROPHONE.ERROR"));
      return;
    }

    const options = MediaRecorder.isTypeSupported("audio/ogg;codecs=opus")
      ? { mimeType: "audio/ogg;codecs=opus" }
      : {};

    this._mediaRecorder = new MediaRecorder(stream, options);

    this._mediaRecorder.addEventListener("dataavailable", (e) => {
      if (e.currentTarget.state === "inactive") return;

      switch (this._webSocket.readyState) {
        case WebSocket.OPEN:
          this._webSocket.send(e.data);
          break;
        case WebSocket.CLOSING:
          console.debug("WebSocket is closing.");
          break;
        case WebSocket.CLOSED:
          console.log("Stopping media recorder.");
          this._stopMediaRecorder();
          console.error("WebSocket is not open");
          this._handleErrors(t("FORM.WEBSOCKET.NOTOPEN"));
          break;
        default:
          console.error(
            "Unknown WebSocket state: ",
            this._webSocket.readyState
          );
          break;
      }
    });

    this._mediaRecorder.addEventListener("stop", () => {
      console.log("MediaRecorder stopped");
    });

    this._mediaRecorder.start(CHUNK_DURATION);

    if (this._maxSpeechTime) {
      setTimeout(() => {
        this.close();
      }, this._maxSpeechTime);
    }
  }

  _setupWebSocket() {
    console.log("Recorder state: ", this._recorderStateRef.current);
    this._webSocket = new WebSocket(process.env.REACT_APP_API_SOCKET_URL);
    this._startConnectingTimeout();

    this._webSocket.onopen = () => {
      this._webSocket.send(this._startMessage);
      this._cancelConnectingTimeout();
      this._initialFormState
        ? this.sendFormState(this._initialFormState)
        : console.warn("Did not send initial form state. It is not set.");
      this.sendLanguage();
    };

    this._webSocket.onmessage = (event) => {
      try {
        const jsonData = JSON.parse(event.data);
        this._onDataReceive(jsonData);
      } catch {
        this._onDataReceive(event.data);
      }
    };

    this._webSocket.onerror = (errorEvent) => {
      console.error("WebSocket error:", errorEvent);
      this._handleErrors(t("FORM.WEBSOCKET.ERROR"));
    };

    this._webSocket.onclose = () => {
      console.log("WebSocket is closed now.");
      if (this._mediaRecorder && this._mediaRecorder.state !== "inactive") {
        this._stopMediaRecorder();
      }
      console.log("Recorder state: ", this._recorderStateRef.current);
      if (this._recorderStateRef.current === RecorderStateType.RECORDING) {
        this._handleErrors(t("FORM.WEBSOCKET.CLOSED"));
      }
    };
  }

  _stopMediaRecorder() {
    this._mediaRecorder.stream.getTracks().forEach((track) => track.stop());
  }

  _onDataReceive(data) {
    console.log("Recorder state: ", this._recorderStateRef.current);
    switch (data.type) {
      case "transcription":
        this._onTranscriptionReceive(data.payload);
        break;
      case "form":
        this._onFormReceive(data.payload);
        break;
      default:
        throw new Error(`Unknown type: ${data.type}. Data: ${data}`);
    }
  }

  _startConnectingTimeout() {
    this._cancelConnectingTimeout();
    this._connectingTimeout = setTimeout(() => {
      console.error("Could not connect to the server.");
      this._handleErrors(t("FORM.WEBSOCKET.NOTOPEN"));
      this.close();
    }, this._connectingTimeoutMillis);
  }

  _cancelConnectingTimeout() {
    if (this._connectingTimeout) clearTimeout(this._connectingTimeout);
    else console.warn("No connecting timeout to cancel.");
  }
}

export default SpeechToFormStreamer;
