import React from "react";
import { useField } from "formik";
import {
  Checkbox,
  FormControlLabel,
  FormControl,
  FormLabel,
  FormGroup,
  FormHelperText,
} from "@material-ui/core";

const MultiplechoiceComponent = ({
  name,
  label,
  options,
  disabled = false,
}) => {
  const [field, meta] = useField(name);

  return (
    <FormControl
      component="fieldset"
      error={meta.touched && Boolean(meta.error)}
      fullWidth
    >
      <FormLabel component="legend">{label}</FormLabel>
      <FormGroup>
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            control={
              <Checkbox
                {...field}
                value={option.value}
                checked={field.value.includes(option.value)}
                disabled={disabled}
              />
            }
            label={option.label}
          />
        ))}
      </FormGroup>
      {meta.touched && <FormHelperText>{meta.error}</FormHelperText>}
    </FormControl>
  );
};

export default MultiplechoiceComponent;
