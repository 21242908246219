import React from "react";
import { useField } from "formik";
import {
  Radio,
  FormControlLabel,
  FormControl,
  FormLabel,
  FormHelperText,
  RadioGroup as MuiRadioGroup,
} from "@material-ui/core";

const SinglechoiceComponent = ({ name, label, options, disabled = false }) => {
  const [field, meta] = useField(name);

  return (
    <FormControl
      component="fieldset"
      error={meta.touched && Boolean(meta.error)}
      fullWidth
    >
      <FormLabel component="legend">{label}</FormLabel>
      <MuiRadioGroup {...field} name={name}>
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={<Radio />}
            label={option.label}
            disabled={disabled}
          />
        ))}
      </MuiRadioGroup>
      {meta.touched && <FormHelperText>{meta.error}</FormHelperText>}
    </FormControl>
  );
};

export default SinglechoiceComponent;
