import React from "react";
import { Typography, Link } from "@material-ui/core";

function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <div className="footer-container">
      <Typography className="footer-text" align="center">
        Developed by{" "}
        <Link
          href="https://www.t-systems.com/"
          color="inherit"
          style={{ "font-weight": "bold" }}
        >
          T-Systems
        </Link>
        {" and "}
        <Link
          href="https://omnios.ai/"
          color="inherit"
          style={{ "font-weight": "bold" }}
        >
          Omnios
          {/* Omnios Cognitive Solutions, S.L. */}
        </Link>{" "}
        © {currentYear}
      </Typography>
    </div>
  );
}

export default Footer;
