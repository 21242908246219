import React from "react";
import { useField } from "formik";
import { TextField } from "@material-ui/core";

const TextFieldComponent = ({
  name,
  label,
  disabled = false,
  required = false,
}) => {
  const [field, meta] = useField(name);

  return (
    <TextField
      {...field}
      label={label}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
      fullWidth
      disabled={disabled}
      required={required}
    />
  );
};

export default TextFieldComponent;
