export function stringToEnum(s, Enum) {
  const fields = Object.keys(Enum);
  for (let i = 0; i < fields.length; i++) {
    if (
      Enum[fields[i]].description.toLowerCase().replace("_", "") ===
      s.toLowerCase().replace("_", "")
    ) {
      return Enum[fields[i]];
    }
  }
}

export function enumToString(e, Enum) {
  const fields = Object.keys(Enum);
  for (let i = 0; i < fields.length; i++) {
    if (Enum[fields[i]] === e) {
      return fields[i];
    }
  }
}

export function enumToDescription(e, Enum) {
  const fields = Object.keys(Enum);
  for (let i = 0; i < fields.length; i++) {
    if (Enum[fields[i]] === e) {
      return Enum[fields[i]].description;
    }
  }
}
