import React, { useEffect, useState } from "react";
import { useField } from "formik";
import {
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
  InputLabel,
} from "@material-ui/core";

const FormDropdown = ({
  name,
  label,
  options,
  disabled = false,
  open = null,
  multiple = false,
}) => {
  const [field, meta, helpers] = useField(name);

  const [isOpen, setIsOpen] = useState(open);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  useEffect(() => {
    if (open !== null) setIsOpen(open);
  }, [isOpen]);

  useEffect(() => {
    const handleScroll = () => {
      if (isOpen) {
        setIsOpen(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isOpen]);

  return (
    <FormControl error={meta.touched && Boolean(meta.error)} fullWidth>
      <InputLabel id={name}>{label}</InputLabel>
      <Select
        multiple={multiple}
        labelId={name}
        id={name}
        value={multiple ? field.value || [] : field.value}
        onChange={(event) => {
          helpers.setValue(event.target.value);
        }}
        disabled={disabled}
        open={isOpen}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        MenuProps={{
          disableScrollLock: true,
          disableBackdropClick: true,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          getContentAnchorEl: null,
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value} disabled={disabled}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {meta.touched && meta.error && (
        <FormHelperText>{meta.error}</FormHelperText>
      )}
    </FormControl>
  );
};

export default FormDropdown;
