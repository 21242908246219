import TextType from "../components/forms/fields/typification/TextType";
import callApi from "./callApi";
import i18n from "../i18n";

const validateInput = (text = "", language = "ca-ES") => {
  if (!text) throw new Error("Text not specified");
  if (typeof text !== "string") throw new Error("Text must be a string");
  if (typeof language !== "string")
    throw new Error("Language must be a string");
};

const playAudioFromBase64 = (audioContent) => {
  return new Promise((resolve, reject) => {
    const audio = new Audio(`data:audio/mp3;base64,${audioContent}`);
    audio.onended = resolve;
    audio.onerror = reject;
    audio.play();
  });
};

const textToSpeech = async (text = "", language = "ca-ES") => {
  validateInput(text, language);

  try {
    const endpoint = "text2speech";
    const response = await callApi("POST", endpoint, { text, language });
    const data = response.data;
    return playAudioFromBase64(data.audioContent);
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const readText = (text, displayText = () => {}) => {
  const language = i18n.language;
  return textToSpeech(text, language).catch((err) => {
    displayText(text);
    throw err;
  });
};

export const getSpeechText = (text, type = TextType.REGULAR) => {
  switch (type) {
    case TextType.ALPHANUMERIC:
      return text
        .split("")
        .map((c) => `"${c}"`)
        .join(" ");
    case TextType.REGULAR:
      return text;
    default:
      throw new Error(`Type ${type} not supported`);
  }
};

export default textToSpeech;
