import React, { useState } from "react";
import { ListItem, ListItemText, Tooltip } from "@material-ui/core";
import { listForms } from "../../utils/apiMethods";
import { formatDate } from "../../utils/dateUtils";
import ArchiveIcon from "@material-ui/icons/Archive";
import MenuSection from "./MenuSection";
import { useTranslation } from "react-i18next";
import { convertMultilingualPropertyToLanguage } from "../../utils/formLanguageConversionUtils";

const FormsArchiveSection = ({ onFormSelected }) => {
  const [forms, setForms] = useState([]);
  const { t, i18n } = useTranslation();

  const retrieveFormsList = () => {
    return listForms()
      .then((formsData) => {
        setForms(
          formsData
            .filter((form) => form && form.type === "instance")
            .map((form) => ({
              id: form.id,
              label: convertMultilingualPropertyToLanguage(
                form.label,
                i18n.language
              ),
              date: formatDate(form.created_at),
              raw_date: form.created_at,
            }))
            .sort((a, b) => (a.raw_date < b.raw_date ? 1 : -1))
        );
      })
      .catch((error) => {
        console.log(error);
        throw new Error("No s'ha pogut obtenir el llistat de formularis");
      });
  };

  return (
    <MenuSection
      title={t("HOMEPAGE.FORMS.TITLE")}
      icon={ArchiveIcon}
      loadContent={retrieveFormsList}
      errorMessage="No s'ha pogut obtenir el llistat de formularis"
    >
      {forms.map((item) => (
        <ListItem
          button
          key={item.id}
          onClick={() => onFormSelected(item.id)}
          onKeyDown={() => onFormSelected(item.id)}
          className="dropable-item"
        >
          <Tooltip title={item.date || ""}>
            <ListItemText primary={item.label} />
          </Tooltip>
        </ListItem>
      ))}
    </MenuSection>
  );
};

export default FormsArchiveSection;
