import {
  enumToString,
  stringToEnum,
} from "../../../../utils/typification/enums";

const FormComponentType = {
  TEXT_FIELD: Symbol("textField"),
  TEXT_AREA: Symbol("textArea"),
  EMAIL: Symbol("email"),
  PASSWORD: Symbol("password"),
  NUMBER: Symbol("number"),
  DATE: Symbol("date"),
  SINGLE_CHOICE: Symbol("singlechoice"),
  MULTIPLE_CHOICE: Symbol("multiplechoice"),
  DROPDOWN: Symbol("dropdown"),
};

export function stringToComponentType(s) {
  try {
    return stringToEnum(s, FormComponentType);
  } catch (err) {
    console.error(err);
    throw err; // Re-throwing the error to allow further handling by the caller
  }
}

export function componentTypeToString(c) {
  try {
    return enumToString(c, FormComponentType);
  } catch (err) {
    console.error(err);
    throw err; // Re-throwing the error to allow further handling by the caller
  }
}

export default FormComponentType;
