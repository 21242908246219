import { stringToComponentType } from "./../components/forms/fields/typification/FormComponentType";

export default function parseFormData(data) {
  let components = data.components;
  let parsedData = data;
  parsedData.components = [];
  components.forEach((component) => {
    let parsedComponent = component;
    parsedComponent.type = stringToComponentType(component.type);
    parsedData.components.push(parsedComponent);
  });
  return parsedData;
}
