import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  TextField,
  Container,
  Typography,
  CssBaseline,
  InputAdornment,
  IconButton,
  Snackbar,
  Paper,
  Box,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import "./../App.css";
import { UserContext } from "../contexts/UserContext";
import { loginUser } from "../utils/apiMethods";
import { RequestError } from "../utils/errors";
import { NavigationContext } from "../contexts/NavigationContext";
import { loginLogo1, loginLogo2 } from "../utils/lookAndFeel";

export default function LoginPage() {
  const { setUser } = useContext(UserContext);
  const { handleNavigate } = useContext(NavigationContext);
  const { t } = useTranslation();

  const [values, setValues] = useState({
    email: "",
    password: "",
    showPassword: false,
  });
  const [error, setError] = useState(null);
  const [alertOpen, setAlertOpen] = useState(false);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    await loginUser(values.email, values.password)
      .then((response) => {
        if (response.status_code !== 200) {
          throw new RequestError(response);
        }
        const user = response.data.user;
        setUser({
          ...user,
          name: `${user.name} ${user.surname}`,
          formIds: user.form_ids,
        });
        const token = response.data.token;
        localStorage.setItem("access_token", token);
        handleNavigate("/");
        setError(null);
      })
      .catch((error) => {
        try {
          const response = error.response;
          if (response.message.toLowerCase().includes("network error")) {
            setError(t("LOGINPAGE.MODAL.ERROR.NETWORK"));
          } else if (
            response.message.toLowerCase().includes("invalid credentials") ||
            response.status_code === 401
          ) {
            setError(t("LOGINPAGE.MODAL.ERROR.CREDENTIALS"));
          } else if (
            response.message.toLowerCase().includes("failed to fetch") ||
            (response.status_code > 400 && response.status_code < 600)
          ) {
            setError(t("LOGINPAGE.MODAL.ERROR.FETCH"));
          } else {
            throw new Error(error);
          }
        } catch (error) {
          setError(t("LOGINPAGE.MODAL.ERROR.UNKNOWN"));
        }
        setAlertOpen(true);
      });
  };

  return (
    <div className="loginPage">
      {/* Image section */}
      <div className="loginImage"></div>

      {/* Login form section */}
      <div className="loginFormWrapper">
        <Container
          component="main"
          className="loginFormContainer"
          maxWidth="sm"
        >
          <Box component={Paper} p={3} elevation={3} className="loginFormBox">
            <CssBaseline />
            <div className="flex-center formHeader">
              <Typography component="h1" variant="h5">
                {t("LOGINPAGE.TITLE")}
              </Typography>
            </div>
            <form noValidate onSubmit={handleSubmit}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label={t("LOGINPAGE.USERNAME")}
                name="email"
                autoComplete="email"
                autoFocus
                className="formField"
                onChange={handleChange("email")}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label={t("LOGINPAGE.PASSWORD")}
                type={values.showPassword ? "text" : "password"}
                id="password"
                autoComplete="current-password"
                className="formField"
                onChange={handleChange("password")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onKeyDown={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        onFocus={handleMouseDownPassword}
                      >
                        {values.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className="main-button"
              >
                {t("LOGINPAGE.BUTTON.LOGIN")}
              </Button>
            </form>
            {/* <Grid container className="componentMargin">
        <Grid item xs>
          <a href="#" variant="body2">
            Forgot password?
          </a>
        </Grid>
        <Grid item>
          <a href="#" variant="body2">
            {"Don't have an account? Sign Up"}
          </a>
        </Grid>
      </Grid> */}
            {loginLogo1 || loginLogo2 ? (
              <div className="flex-center loginLogoContainer">
                {loginLogo1 ? (
                  <img
                    src={loginLogo1}
                    alt="Login Logo 1"
                    className="loginLogo1"
                  />
                ) : null}
                {loginLogo2 ? (
                  <>
                    <div className="loginLogoContainer__line"></div>
                    <img
                      src={loginLogo2}
                      alt="Login Logo 2"
                      className="loginLogo2"
                    />
                  </>
                ) : null}
              </div>
            ) : null}
          </Box>
        </Container>
        <Snackbar
          open={alertOpen}
          autoHideDuration={6000}
          onClose={() => setAlertOpen(false)}
        >
          <Alert
            elevation={6}
            variant="filled"
            onClose={() => setAlertOpen(false)}
            severity="error" // Since it's an error message
          >
            {error}
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
}
