import { componentTypeToString } from "../components/forms/fields/typification/FormComponentType";
import callApi, { ContentType } from "./callApi";

const loginUser = async (username, password) => {
  return await callApi(
    "POST",
    "auth/login",
    { username, password },
    ContentType.FORM
  );
};

const logoutUser = async () => {
  return await callApi("POST", "auth/logout");
};

const checkUser = async () => {
  return await callApi("POST", "auth/check");
};

const getForm = async (id) => {
  return await callApi("GET", `forms/${id}`).then((response) => {
    return response.data;
  });
};

const listForms = async () => {
  return await callApi("GET", `forms`).then((response) => {
    return response.data;
  });
};

// !! This is what will be done in the project stage, not PoC
// const getUserForms = async (userId) => {
//   return await callApi("GET", `forms?userId=${userId}`).then((response) => {
//     return response.data;
//   });
// };

// For PoC stage:
const getUserForms = async (userData) => {
  const formIds = userData.formIds;
  return await Promise.all(
    formIds.map(async (id) => {
      try {
        return await getForm(id);
      } catch (error) {
        console.error(error);
      }
    })
  );
};
const createForm = async (template, components, name = null) => {
  let body = {
    components: convertToApiFormat(components),
  };
  if (name) {
    body.name = name;
  }
  if (template) {
    body.template = template;
  }
  return await callApi("POST", `forms`, body, ContentType.FORM);
};

const updateForm = async (id, params) => {
  if (params.components) {
    params.components = convertToApiFormat(params.components);
  }
  return await callApi("PUT", `forms/${id}`, params, ContentType.JSON);
};

const deleteForm = async (id) => {
  return await callApi("DELETE", `forms/${id}`).then((response) => {
    return response.data;
  });
};

const convertToApiFormat = (formData) => {
  return formData.map((component) => {
    component.type = componentTypeToString(component.type);
    component.initialValue = component.value;
    return component;
  });
};

export {
  loginUser,
  logoutUser,
  checkUser,
  getForm,
  createForm,
  updateForm,
  deleteForm,
  listForms,
  getUserForms,
};
