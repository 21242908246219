import FormComponentType from "./fields/typification/FormComponentType";
import * as Yup from "yup";

export function validationSchema(components, requiredText) {

  let schema = Yup.object().shape({});
  components.forEach((component) => {
    switch (component.type) {
      case FormComponentType.EMAIL:
        schema = schema.shape({
          [component.name]: component.required
            ? Yup.string().email("Email invàlid").required(requiredText)
            : Yup.string().email("Email invàlid"),
        });
        break;
      case FormComponentType.PASSWORD:
        schema = schema.shape({
          [component.name]: component.required
            ? Yup.string()
                .min(8, "La contrasenya ha contenir com a mínim 8 caràcters")
                .required(requiredText)
            : Yup.string().min(
                8,
                "La contrasenya ha contenir com a mínim 8 caràcters"
              ),
        });
        break;
      case FormComponentType.SINGLE_CHOICE:
      case FormComponentType.DROPDOWN:
        schema = schema.shape({
          [component.name]: component.multiple
            ? component.required
              ? Yup.array().required(requiredText)
              : Yup.array()
            : component.required
            ? Yup.string().required(requiredText)
            : Yup.string(),
        });
        break;
      case FormComponentType.MULTIPLE_CHOICE:
        schema = schema.shape({
          [component.name]: component.required
            ? Yup.array().required(
                "Com a mínim una opció ha de ser seleccionada"
              )
            : Yup.array(),
        });
        break;
      default:
        schema = schema.shape({
          [component.name]: component.multiple
            ? component.required
              ? Yup.array().required(requiredText)
              : Yup.array()
            : component.required
            ? Yup.string().required(requiredText)
            : Yup.string(),
        });
        break;
    }
  });
  return schema;
}
