import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import SpanishTranslations from "./messages/es.json";
import CatalanTranslations from "./messages/ca.json";

const SUPPORTED_LANGUAGES = ["es-ES", "ca-ES"];

const resources = {
  "es-ES": {
    translation: SpanishTranslations,
  },
  "ca-ES": {
    translation: CatalanTranslations,
  },
};

const detectionOptions = {
  order: [
    "querystring",
    "cookie",
    "localStorage",
    "sessionStorage",
    "navigator",
    "htmlTag",
    "path",
    "subdomain",
  ],
  lookupQuerystring: "lng",
  lookupCookie: "i18next",
  lookupLocalStorage: "i18nextLng",
  lookupSessionStorage: "i18nextLng",
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,
  caches: ["localStorage"],
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "ca-ES",
    supportedLngs: SUPPORTED_LANGUAGES,
    nonExplicitSupportedLngs: false,
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    detection: detectionOptions,
  });

export const isSupportedLanguage = (lang) =>
  SUPPORTED_LANGUAGES.some((lng) => lng === lang);

export default i18n;
