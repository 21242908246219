import React from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { removeRegionCode } from "../../utils/languageUtils";

const TitleCard = ({
  id,
  title = "Untitled",
  handleClick = () => {},
  disabled = false,
}) => {
  const { i18n } = useTranslation();

  const handleClickAction = () => {
    if (handleClick) handleClick();
  };

  return (
    <div
      className={clsx(
        "titleCard-root",
        { "titleCard-disabled": disabled },
        {
          [`titleCard-style-unlock-A-${removeRegionCode(i18n.language)}`]:
            id === "A" && !disabled,
        },
        {
          [`titleCard-style-unlock-B-${removeRegionCode(i18n.language)}`]:
            id === "B" && !disabled,
        },
        {
          [`titleCard-style-lock-A-${removeRegionCode(i18n.language)}`]:
            id === "A" && disabled,
        },
        {
          [`titleCard-style-lock-B-${removeRegionCode(i18n.language)}`]:
            id === "B" && disabled,
        }
      )}
      onClick={!disabled && handleClickAction}
      onKeyDown={!disabled && handleClickAction}
    ></div>
  );
};

export default TitleCard;
