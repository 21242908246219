import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Formik, Form } from "formik";
import {
  Box,
  Grid,
  Paper,
  Snackbar,
  Typography,
  Button,
} from "@material-ui/core";
import FormComponentWrapper, {
  ComponentState,
} from "./fields/FormComponentWrapper";
import { Alert } from "@material-ui/lab";
import { NavigationContext } from "../../contexts/NavigationContext";
import useLocalStorage from "../../hooks/useLocalStorage";
import {
  areValuesEqual as areObjectsEqual,
  hasNonEmptyValue,
} from "../../utils/objectsUtils";
import { validationSchema } from "./validationSchema";
import StatusType from "../../typification/StatusType";

const CustomForm = ({
  status = "enabled",
  name = "Sense nom",
  componentsProps = [],
  handleSubmit = () => {},
  defaultState = ComponentState.SUCCESS,
  onFormStateUpdate = () => {},
}) => {
  const UNSAVED_FORM_DATA_KEY = "unsavedFormData";
  const { handleNavigate } = useContext(NavigationContext);

  const [components, setComponents] = useState(
    componentsProps.map((component, index) => ({
      ...component,
      state: defaultState,
      index: index,
    }))
  );

  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastSeverity, setToastSeverity] = useState("success"); // can be 'success', 'error', 'warning', 'info'

  const [unsavedFormData, setUnsavedFormData] = useLocalStorage(
    UNSAVED_FORM_DATA_KEY,
    null
  );

  const [requiredFieldText, setRequiredFieldText] = useState("");
  const { t } = useTranslation();

  const getInitialValues = () => {
    const initialValues = {};
    components.forEach((component) => {
      initialValues[component.name] = component.multiple
        ? component.initialValue
          ? !Array.isArray(component.initialValue)
            ? [component.initialValue]
            : component.initialValue
          : []
        : component.initialValue;
    });
    return initialValues;
  };

  useEffect(() => {
    setRequiredFieldText(t("FORM.NEEDED.FIELD"));
  }, [t]);

  const isFormDirty =
    unsavedFormData !== null &&
    unsavedFormData !== undefined &&
    !areObjectsEqual(unsavedFormData, getInitialValues());

  useEffect(() => {
    localStorage.removeItem(UNSAVED_FORM_DATA_KEY);
    return () => {
      localStorage.removeItem(UNSAVED_FORM_DATA_KEY);
    };
  }, []);

  useEffect(() => {
    setComponents(
      componentsProps.map((component, index) => ({
        ...component,
        state: defaultState,
        index: index,
      }))
    );
  }, [componentsProps]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (!isFormDirty) return;
      event.preventDefault();
      event.returnValue =
        "Si tanques el formulari sense enviar-lo, es perdran els canvis realitzats.";
      localStorage.removeItem(UNSAVED_FORM_DATA_KEY);
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, [isFormDirty]);

  const popToastMessage = (message, severity) => {
    setToastMessage(message);
    setToastSeverity(severity);
    setToastOpen(true);
  };

  function handleUnsavedChanges(data) {
    if (
      status === StatusType.BLOCKED ||
      !data ||
      Object.keys(data).length === 0 ||
      !hasNonEmptyValue(data)
    )
      return;
    setUnsavedFormData(data);
  }

  return (
    <Grid
      container
      spacing={3}
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        component={Paper}
        p={3}
        elevation={3}
        className="detailFormContainer"
      >
        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          className="formHeader"
        >
          {name}
        </Typography>

        <Formik
          initialValues={getInitialValues()}
          validationSchema={validationSchema(components, requiredFieldText)}
          onSubmit={(values) => {
            handleSubmit(
              components.map((component) => ({
                ...component,
                value: values[component.name],
              }))
            )
              .then((response) => {
                console.log("Form saved successfully.");
                localStorage.removeItem(UNSAVED_FORM_DATA_KEY);
                popToastMessage(t("FORM.TOASTMESSAGE.SUCCESS"), "success");
                setTimeout(() => {
                  handleNavigate("/");
                }, 1500);
              })
              .catch((error) => {
                console.log("Error saving form: ", error);
                popToastMessage(t("FORM.TOASTMESSAGE.ERROR"), "error");
              });
          }}
        >
          {({ errors, touched, values, setFieldValue }) => {
            const formData = components.map((component) => ({
              ...component,
              value: values[component.name],
            }));

            useEffect(() => {
              handleUnsavedChanges(formData);
              onFormStateUpdate(formData);
            }, [values]);

            useEffect(() => {
              components.forEach((component) => {
                if (component && component.value !== undefined) {
                  setFieldValue(component.name, component.value);
                }
              });
            }, [components]);

            return (
              <Form>
                {components.map((component) => (
                  <Grid
                    item
                    xs={12}
                    className="formField componentMargin"
                    key={component.name}
                  >
                    <div className="fullHeight">
                      <FormComponentWrapper
                        key={component.name}
                        state={
                          status === StatusType.BLOCKED
                            ? component.state
                            : component.state
                        }
                        className="formField formComponent"
                        componentConfig={{ component }}
                        disabled={status === StatusType.BLOCKED}
                      />
                    </div>
                  </Grid>
                ))}
                {status !== StatusType.BLOCKED ? (
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    className="formBottomButtonsContainer"
                  >
                    <Button type="submit" color="primary" variant="contained">
                      {t("FORM.BUTTON.SUBMIT")}
                    </Button>
                  </Grid>
                ) : null}
              </Form>
            );
          }}
        </Formik>
      </Box>
      <Snackbar
        open={toastOpen}
        autoHideDuration={6000}
        onClose={() => setToastOpen(false)}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={() => setToastOpen(false)}
          severity={toastSeverity}
        >
          {toastMessage}
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export default CustomForm;
