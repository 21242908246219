import React from "react";
import FormComponentType from "./typification/FormComponentType";
import FormTextField from "./FormTextField";
import FormTextArea from "./FormTextArea";
import FormEmailField from "./FormEmailField";
import FormPasswordField from "./FormPasswordField";
import FormSingleChoice from "./FormSingleChoice";
import FormMultipleChoice from "./FormMultipleChoice";
import FormDropdown from "./FormDropdown";
import FormNumericField from "./FormNumericField";
import FormDateField from "./FormDateField";
import DynamicTextFields from "./FormDynamicTextFields";

export default function createFormComponent(type, props) {
  switch (type) {
    case FormComponentType.TEXT_FIELD:
      if (props.multiple) return <DynamicTextFields {...props} />;
      else return <FormTextField {...props} />;
    case FormComponentType.TEXT_AREA:
      return <FormTextArea {...props} />;
    case FormComponentType.EMAIL:
      return <FormEmailField {...props} />;
    case FormComponentType.PASSWORD:
      return <FormPasswordField {...props} />;
    case FormComponentType.NUMBER:
      return <FormNumericField {...props} />;
    case FormComponentType.DATE:
      return <FormDateField {...props} />;
    case FormComponentType.SINGLE_CHOICE:
      return <FormSingleChoice {...props} />;
    case FormComponentType.MULTIPLE_CHOICE:
      return <FormMultipleChoice {...props} />;
    case FormComponentType.DROPDOWN:
      return <FormDropdown {...props} />;
    default:
      throw new Error(`Invalid component type: ${type}`);
  }
}
