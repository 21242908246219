import { useState, useEffect, useRef } from "react";

function useLocalStorage(key, defaultValue) {
  // Helper functions to handle serialization and deserialization
  const getStoredValue = () => {
    const storedValue = localStorage.getItem(key);
    return storedValue ? JSON.parse(storedValue) : defaultValue;
  };

  const [value, setValue] = useState(getStoredValue);
  const valueRef = useRef(getStoredValue());

  useEffect(() => {
    valueRef.current = value; // Update the ref whenever the value changes
  }, [value]);

  useEffect(() => {
    const handleStorageChange = (e) => {
      if (e.key === key && e.newValue !== JSON.stringify(valueRef.current)) {
        // Deserialize the new value
        setValue(e.newValue ? JSON.parse(e.newValue) : defaultValue);
      }
    };

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [key, defaultValue]);

  const setStoredValue = (newValue) => {
    if (newValue !== valueRef.current) {
      setValue(newValue);
      // Serialize the new value before storing
      localStorage.setItem(key, JSON.stringify(newValue));
    }
  };

  return [value, setStoredValue];
}

export default useLocalStorage;
