import React from "react";
import TranscriptionStateType from "./typification/TranscriptionStateType";
import WineGlass from "../../assets/images/wine.svg";
import { useTranslation } from "react-i18next";

const TranscriptCard = ({ transcriptState, text, prevText }) => {
  const { t } = useTranslation();

  return (
    <div
      className={
        "cardTranscription" +
        (transcriptState === TranscriptionStateType.FINISHED
          ? " cardTranscription-finished"
          : "")
      }
    >
      <div className="cardTranscription__title">
        <span>{t("FORM.TRANSCRIPTCARD.TITLE")}</span>
      </div>
      <div className="cardTranscription__line"></div>
      {transcriptState === TranscriptionStateType.WAITING ? (
        <div className="cardTranscription-body__waiting">
          <img
            src={WineGlass}
            alt="wine"
            className="cardTranscription-body__waiting__wine"
          ></img>
          <div className="cardTranscription-body__waiting__text">
            <span>{t("FORM.TRANSCRIPTCARD.TEXT1")}</span>
            <br></br>
            <span>{t("FORM.TRANSCRIPTCARD.TEXT2")}</span>
          </div>
        </div>
      ) : (
        <div className="cardTranscription-body__text">
          <span>{prevText + text}</span>
        </div>
      )}
    </div>
  );
};

export default TranscriptCard;
