import React, { useState, useEffect } from "react";
import {
  ListItem,
  ListItemText,
  Collapse,
  CircularProgress,
} from "@material-ui/core";

const MenuSection = ({
  title,
  icon: Icon,
  children,
  loadContent,
  errorMessage,
}) => {
  const [expanded, setExpanded] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (expanded && loadContent) {
      setIsLoading(true);
      setError(null);
      loadContent()
        .then(() => setIsLoading(false))
        .catch((err) => {
          setIsLoading(false);
          setError(errorMessage || "An error occurred.");
        });
    }
  }, [expanded]);

  return (
    <>
      <ListItem
        button
        onClick={() => setExpanded(!expanded)}
        onKeyDown={() => setExpanded(!expanded)}
        className="menu-section"
      >
        <Icon className="menu-section-icon" />
        <ListItemText primary={title} />
      </ListItem>
      <Collapse in={expanded}>
        {isLoading ? (
          <div className="flex-center">
            <CircularProgress />
          </div>
        ) : error ? (
          <div className="flex-center">
            <p>{error}</p>
          </div>
        ) : (
          children
        )}
      </Collapse>
    </>
  );
};

export default MenuSection;
