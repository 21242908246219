import { t } from "i18next";

export function convertMultilingualFormToLanguage(data, language) {
  let parsedData = { ...data };
  parsedData.label = convertMultilingualPropertyToLanguage(
    parsedData.label,
    language,
    t("FORM.CONTENT.NO_NAME")
  );
  parsedData.components = data.components.map((component) =>
    convertMultilingualComponentToLanguage(component, language)
  );
  return parsedData;
}

export function convertMultilingualComponentToLanguage(component, language) {
  let parsedComponent = { ...component };
  parsedComponent.label = convertMultilingualPropertyToLanguage(
    component.label,
    language,
    t("FORM.CONTENT.COMPONENT.NO_NAME")
  );

  if (component.speech) {
    parsedComponent.speech = convertMultilingualPropertyToLanguage(
      component.speech,
      language,
      parsedComponent.label
    );
  }

  if (component.options) {
    parsedComponent.options = component.options.map((option) => ({
      ...option,
      label: convertMultilingualPropertyToLanguage(
        option.label,
        language,
        t("FORM.CONTENT.COMPONENT.OPTION.NO_NAME")
      ),
      speech: convertMultilingualPropertyToLanguage(
        option.speech,
        language,
        option.label
      ),
    }));
  }
  return parsedComponent;
}

export function convertMultilingualPropertyToLanguage(
  prop,
  language,
  defaultLabel
) {
  if (typeof prop === "string") {
    return prop;
  } else if (typeof prop === "object") {
    return prop[language] || defaultLabel;
  } else {
    console.error("Form or component label is not a string or an object");
    return defaultLabel;
  }
}
