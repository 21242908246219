import { enumToDescription } from "./typification/enums";

const ContentType = {
  JSON: Symbol("json"),
  FORM: Symbol("x-www-form-urlencoded"),
  OCTET_STREAM: Symbol("octet-stream"),
};

const callApi = async (
  method,
  endpoint,
  body = null,
  contentType = ContentType.JSON,
  timeout = 3000,
  includeCredentials = true,
  cors = true
) => {
  if (!method) {
    throw new Error("API call method not specified");
  }

  if (!endpoint) {
    throw new Error("API call endpoint not specified");
  }

  // if (method === "GET") {
  //   includeCredentials = false;
  // }

  let headers = {};

  // const accessToken = localStorage.getItem("access_token");
  // if (accessToken) {
  //   headers["Authorization"] = `Bearer ${accessToken}`;
  //   includeCredentials = false;
  // }

  const options = {
    method,
    headers,
    credentials: includeCredentials ? "include" : "omit",
    mode: cors ? "cors" : "no-cors",
  };

  if (body) {
    let serializedBody = serialize(body, contentType);
    options.body = serializedBody;
    options.headers["Content-Type"] = `application/${enumToDescription(
      contentType,
      ContentType
    ).toLowerCase()}`;
  }

  try {
    const response = await fetchWithTimeout(
      concatUrlWithBase(process.env.REACT_APP_API_URL, endpoint),
      options,
      timeout
    ).catch((err) => {
      console.error(`API call failed: ${err}`);
      throw new Error(`API call failed: ${err}`);
    });
    if (!response) return;

    if (!response.ok) {
      throw new Error(`API call failed: ${response.statusText}`);
    }

    return await response.json();
  } catch (err) {
    console.error(err);
    throw err; // Re-throwing the error to allow further handling by the caller
  }
};

function fetchWithTimeout(url, options, timeout = 3000) {
  const controller = new AbortController();
  const signal = controller.signal;

  // Set the options.signal to the AbortController's signal
  options = { ...options, signal };

  // Set a timeout to abort the request
  const timeoutId = setTimeout(() => controller.abort(), timeout);

  return fetch(url, options)
    .then((response) => {
      clearTimeout(timeoutId); // Clear the timeout on successful response
      return response;
    })
    .catch((error) => {
      clearTimeout(timeoutId); // Clear the timeout on error
      if (error.name === "AbortError") {
        // Handle fetch abort (timeout scenario)
        throw new Error("Fetch request was aborted due to timeout");
      } else {
        // Handle other fetch errors
        throw error;
      }
    });
}

const serialize = (body, contentType) => {
  if (!body) return null;
  switch (contentType) {
    case ContentType.FORM:
      return serializeAsFormUrlEncoded(body);
    case ContentType.JSON:
      return JSON.stringify(body);
    case ContentType.OCTET_STREAM:
      return body;
    default:
      throw new Error(`Content type ${contentType} not supported`);
  }
};

const concatUrlWithBase = (baseUrl, endpoint) => {
  return `${baseUrl.replace(new RegExp(`/*$`), "")}/${endpoint.replace(
    new RegExp(`^/*`),
    ""
  )}`;
};

const serializeAsFormUrlEncoded = (obj) => {
  return Object.keys(obj)
    .map((k) => {
      let encodedValue;
      if (typeof obj[k] === "object") {
        encodedValue = encodeURIComponent(JSON.stringify(obj[k]));
      } else {
        encodedValue = encodeURIComponent(obj[k]);
      }
      return encodeURIComponent(k) + "=" + encodedValue;
    })
    .join("&");
};

export default callApi;
export { ContentType };
