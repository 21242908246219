export function formatDate(dateText) {
  var d = new Date(dateText);
  function pad(n) {
    return n < 10 ? "0" + n : n;
  }

  var day = pad(d.getDate());
  var month = pad(d.getMonth() + 1); // Months are zero indexed, so +1
  var year = d.getFullYear();

  var hours = pad(d.getHours());
  var minutes = pad(d.getMinutes());
  var seconds = pad(d.getSeconds());

  return (
    day + "/" + month + "/" + year + " " + hours + ":" + minutes + ":" + seconds
  );
}
