import React from "react";
import { useField } from "formik";
import { TextField } from "@material-ui/core";

const PasswordFieldComponent = ({ name, label, disabled = false }) => {
  const [field, meta] = useField(name);

  return (
    <TextField
      {...field}
      label={label}
      type="password"
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
      fullWidth
      disabled={disabled}
    />
  );
};

export default PasswordFieldComponent;
