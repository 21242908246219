import { format } from "date-fns"; // Import format function
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import Microphone from "../../assets/images/microphone.svg";
import PauseRectangle from "../../assets/images/pause_rectangle.svg";
import { Button } from "antd";
import RecorderStateType from "./typification/RecorderStateType";

const Recorder = ({
  name,
  recorderState,
  elapsedTime,
  setRecorderState,
  setElapsedTime,
}) => {
  const [stateText, setStateText] = useState("Comença");

  const { t } = useTranslation();

  useEffect(() => {
    switch (recorderState) {
      case RecorderStateType.IDLE:
        setStateText(t("FORM.RECORDERCARD.TEXT.START"));
        break;
      case RecorderStateType.INSTRUCTIONS:
        setStateText(t("FORM.RECORDERCARD.TEXT.INSTRUCTIONS"));
        break;
      case RecorderStateType.RECORDING:
        setStateText(t("FORM.RECORDERCARD.TEXT.RECORDING"));
        break;
      case RecorderStateType.PAUSED:
        setStateText(t("FORM.RECORDERCARD.TEXT.RESTART"));
        break;
      case RecorderStateType.FINISHED:
        setStateText(t("FORM.RECORDERCARD.TEXT.FINISHED"));
        break;
      default:
        console.debug("State: ", recorderState);
        console.error("Invalid recorder state");
        break;
    }
  }, [recorderState, t]);

  useEffect(() => {
    let interval;
    if (recorderState === RecorderStateType.RECORDING) {
      interval = setInterval(() => {
        setElapsedTime((prevElapsedTime) => prevElapsedTime + 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [recorderState]);

  const handleClick = () => {
    switch (recorderState) {
      case RecorderStateType.IDLE:
        setRecorderState(RecorderStateType.INSTRUCTIONS);
        break;
      case RecorderStateType.INSTRUCTIONS:
        break;
      case RecorderStateType.RECORDING:
        setRecorderState(RecorderStateType.PAUSED);
        break;
      case RecorderStateType.PAUSED:
        setRecorderState(RecorderStateType.RECORDING);
        break;
      default:
        console.debug("State: ", recorderState);
        console.error("Invalid recorder state");
        break;
    }
  };

  const handleClickEnd = () => {
    setRecorderState(RecorderStateType.FINISHED);
  };

  return (
    <div className="recorderCard">
      <div className="recorderCard__title">
        <span>{name}</span>
      </div>
      <div className="recorderCard__line"></div>
      <div className="recorderCard_info">
        <div className="recorderCard__time">
          {recorderState === RecorderStateType.IDLE ||
          recorderState === RecorderStateType.INSTRUCTIONS ? (
            <span>--:--</span>
          ) : (
            <span>{format(new Date(0, 0, 0, 0, 0, elapsedTime), "mm:ss")}</span>
          )}
        </div>
        <div className="recorderCard__state">
          <span>{stateText}</span>
        </div>
        {recorderState === RecorderStateType.FINISHED ? null : (
          <div
            className={`recorderCard__button ${
              recorderState !== RecorderStateType.INSTRUCTIONS
                ? "recorderCard__button-hoverEffect"
                : ""
            }`}
            onClick={handleClick}
            onKeyDown={handleClick}
          >
            {recorderState === RecorderStateType.IDLE ||
            recorderState === RecorderStateType.PAUSED ? (
              <img
                src={Microphone}
                alt="micròfon"
                className="recorderCard__button__mic"
              ></img>
            ) : (
              <div className="recorderCard__button__pause">
                <img
                  src={PauseRectangle}
                  alt="pausa"
                  className="recorderCard__button__pause_rec"
                ></img>
                <img
                  src={PauseRectangle}
                  alt="pausa"
                  className="recorderCard__button__pause_rec"
                ></img>
              </div>
            )}
          </div>
        )}
        {recorderState === RecorderStateType.RECORDING ? (
          <div className="recorderCard__finish">
            <Button
              className="recorderCard__finish__button"
              onClick={handleClickEnd}
              onKeyDown={handleClickEnd}
            >
              {t("FORM.RECORDERCARD.TEXT.FINISH")}
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Recorder;
