import React, { useEffect, useState } from "react";
import CustomForm from "./CustomForm";
import { createForm, getForm } from "../../utils/apiMethods";
import { readText } from "../../utils/textToSpeech";
import SpeechToFormStreamer from "../../utils/speechToForm";
import { useParams } from "react-router-dom";
import parseFormData from "../../utils/parseFormData";
import { CircularProgress, Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import SkeletonComponent from "./Skeleton";
import Recorder from "./Recorder";
import RecorderStateType from "./typification/RecorderStateType";
import StatusType from "../../typification/StatusType";
import TranscriptCard from "./TranscriptCard";
import TranscriptionStateType from "./typification/TranscriptionStateType";
import { useTranslation } from "react-i18next";
import useDeepEffect from "../../hooks/useDeepEffect";
import {
  convertMultilingualFormToLanguage,
  convertMultilingualPropertyToLanguage,
} from "../../utils/formLanguageConversionUtils";

const SpeechForm = ({ status, maxRecordingSeconds = 600 }) => {
  const { formId } = useParams();
  const { i18n, t } = useTranslation();

  const [templateFormData, setTemplateFormData] = useState(null);
  const [formName, setFormName] = useState(t("FORM.CONTENT.NO_NAME"));
  const [components, setComponents] = useState([]);
  const [formState, setFormState] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [transcriptionText, setTranscriptionText] = useState("");
  const [previousTranscriptionText, setPreviousTranscriptionText] =
    useState("");
  const [recorderState, setRecorderState] = useState(RecorderStateType.IDLE);
  const recorderStateRef = React.useRef(recorderState);

  const [transcriptState, setTranscriptState] = useState(
    TranscriptionStateType.WAITING
  );
  const [elapsedTime, setElapsedTime] = useState(0);
  const [error, setError] = useState(null);
  const [alertOpen, setAlertOpen] = useState(false);

  const updateFormData = (newData) => {
    if (!newData) return;

    setComponents((currentComponents) =>
      currentComponents.map((component) => {
        const dataItem = newData.find((item) => item.name === component.name);
        return dataItem ? { ...component, value: dataItem.value } : component;
      })
    );
  };

  const updateTranscriptionText = (transcription) => {
    if (!transcription) return;
    setTranscriptionText(transcription);
  };

  const handleErrors = (errorMessage) => {
    setError(errorMessage);
    setAlertOpen(true);
    setRecorderState(RecorderStateType.IDLE);
    if (speechToFormStreamer && speechToFormStreamer.isStreaming())
      closeVoiceStreaming();
  };

  const handleVoiceErrors = (text) => {
    handleErrors(t("FORM.RECORDERCARD.READING.ERROR", { text: text }));
  };

  const [speechToFormStreamer, setSpeechToFormStreamer] = useState(null);

  useEffect(() => {
    setSpeechToFormStreamer(
      new SpeechToFormStreamer(
        updateFormData,
        updateTranscriptionText,
        handleErrors,
        recorderStateRef
      )
    );
  }, []);

  useDeepEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      console.debug("Fetching form definition for form id: ", formId);
      if (!formId) return;
      const formData = await getForm(formId);
      const parsedFormData = await parseFormData(formData, i18n.language);
      setTemplateFormData(parsedFormData);
      const translatedFormData = await convertMultilingualFormToLanguage(
        parsedFormData,
        i18n.language
      );
      setFormName(translatedFormData.label);
      setComponents([...translatedFormData.components]);
      setIsLoading(false);
    };

    fetchData();
  }, [formId, i18n.language]);

  const handleSubmit = async (values) => {
    console.log("Clicked submit with values: ", values);
    const multilingualComponents = await fillFormWithValues(
      values,
      templateFormData
    );
    const templateId = formId;
    return createForm(templateId, multilingualComponents);
  };

  function fillFormWithValues(values, form) {
    return form.components.map((component) => ({
      ...component,
      value: values.find((value) => value.name === component.name).value,
    }));
  }

  const explainInstructions = async () => {
    if (components.length === 0) {
      setRecorderState(RecorderStateType.IDLE);
      return;
    }

    const speechName = formName.replace(/Formulario?\s*/gi, "");
    await readText(
      t("VOICE.FORM.WELCOME", { form_name: speechName }),
      handleVoiceErrors
    );
    await readComponents(components);
  };

  const readComponents = async (components) => {
    let textToRead = "";

    if (components.length === 1) {
      textToRead = t("VOICE.FORM.FIELDS.ONE", {
        field_name: components[0].name,
      });
    } else {
      const componentNames = components.map((component) => {
        let name =
          convertMultilingualPropertyToLanguage(
            component.speech,
            i18n.language
          ) ||
          convertMultilingualPropertyToLanguage(component.label, i18n.language);
        return name;
      });
      const lastComponentName = componentNames.pop();
      textToRead = t("VOICE.FORM.FIELDS.MORE", {
        field_list: componentNames.join(", "),
        field_last: lastComponentName,
      });
    }
    await readText(textToRead, handleVoiceErrors);
  };

  const regresiveCountdown = async (seconds) => {
    await readText(t("VOICE.FORM.COUNTDOWN"), handleVoiceErrors);
    for (let i = seconds; i > 0; i--) {
      await readText(i.toString(), handleVoiceErrors);
    }
    await new Promise((resolve) => setTimeout(resolve, 500));
  };

  function recorderStateChangeHandler() {
    const handleInstructions = async () => {
      if (
        !process.env.REACT_APP_NO_AUDIO ||
        process.env.REACT_APP_NO_AUDIO !== "true"
      ) {
        await explainInstructions().catch((error) => {
          console.error("Error reading instructions: ", error);
        });
      }
      await regresiveCountdown(3).catch((error) => {
        console.error("Error reading countdown: ", error);
      });
      setRecorderState(RecorderStateType.RECORDING);
    };

    const handleRecording = () => {
      if (!speechToFormStreamer.isStreaming()) {
        speechToFormStreamer.setInitialFormState(formState);
        startVoiceStreaming();
      }
      if (maxRecordingSeconds) {
        setTimeout(() => {
          closeVoiceStreaming();
          setRecorderState(RecorderStateType.FINISHED);
        }, maxRecordingSeconds * 1000);
      }
    };

    const handleFinished = () => {
      if (speechToFormStreamer.isStreaming()) closeVoiceStreaming();
    };

    const handlePaused = () => {
      if (speechToFormStreamer.isStreaming()) closeVoiceStreaming();
      handleTextOnPause();
    };

    function handleTextOnPause() {
      if (previousTranscriptionText) {
        setPreviousTranscriptionText(
          previousTranscriptionText + " " + transcriptionText
        );
      } else {
        setPreviousTranscriptionText(transcriptionText + " ");
      }
      setTranscriptionText("");
    }

    switch (recorderState) {
      case RecorderStateType.IDLE:
        break;
      case RecorderStateType.INSTRUCTIONS:
        handleInstructions();
        break;
      case RecorderStateType.RECORDING:
        handleRecording();
        setTranscriptState(TranscriptionStateType.RECORDING);
        break;
      case RecorderStateType.FINISHED:
        handleFinished();
        setTranscriptState(TranscriptionStateType.FINISHED);
        break;
      case RecorderStateType.PAUSED:
        handlePaused();
        break;
      default:
        throw new Error(`Invalid recorder state ${recorderState}`);
    }
  }

  useEffect(() => {
    recorderStateRef.current = recorderState;

    recorderStateChangeHandler();
  }, [recorderState]);

  const startVoiceStreaming = () => {
    console.debug("Starting voice streaming");
    speechToFormStreamer.start();
  };

  const closeVoiceStreaming = () => {
    console.debug("Stopping voice streaming");
    speechToFormStreamer.close();
  };

  const handleFormStateUpdate = (formState) => {
    if (!(formState && Array.isArray(formState) && formState.length > 0))
      return;
    setFormState(formState);
    switch (recorderState) {
      case RecorderStateType.RECORDING || RecorderStateType.FINISHED:
        sendFormState(formState);
        break;
      default:
        break;
    }
  };

  const sendFormState = (formState) => {
    if (speechToFormStreamer && speechToFormStreamer.isStreaming()) {
      speechToFormStreamer.sendFormState(formState);
    } else {
      console.error(
        `Could not send form state: ${formState}. Speech2Form is ${
          speechToFormStreamer && speechToFormStreamer.isStreaming()
            ? ""
            : "not"
        } streaming`
      );
    }
  };

  function hideFormSkeleton() {
    return (
      (process.env.REACT_APP_HIDE_FORM_SKELETON &&
        process.env.REACT_APP_HIDE_FORM_SKELETON === "true") ||
      recorderState === RecorderStateType.FINISHED ||
      status === StatusType.BLOCKED
    );
  }

  return isLoading ? (
    <div className="pageProgessContainer">
      <CircularProgress />
    </div>
  ) : (
    <div className="formBackground">
      <div className="formComponentContainer">
        {status === StatusType.BLOCKED ? null : (
          <div className="descriptionContainer">
            <div className="recordButtonContainer">
              <Recorder
                name={formName}
                recorderState={recorderState}
                elapsedTime={elapsedTime}
                setRecorderState={setRecorderState}
                setElapsedTime={setElapsedTime}
              />
            </div>
            <div className="transcriptionContainer">
              <TranscriptCard
                transcriptState={transcriptState}
                text={transcriptionText}
                prevText={previousTranscriptionText}
              />
            </div>
          </div>
        )}
        <div className="formContainer">
          <div className={hideFormSkeleton() ? "" : "invisible"}>
            <CustomForm
              key={formId}
              name={formName}
              componentsProps={components}
              status={status}
              onFormStateUpdate={handleFormStateUpdate}
              handleSubmit={status === StatusType.BLOCKED ? null : handleSubmit}
            />
          </div>
          <div className={hideFormSkeleton() ? "invisible" : ""}>
            <SkeletonComponent state={recorderState} />
          </div>
        </div>
      </div>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={() => setAlertOpen(false)}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={() => setAlertOpen(false)}
          severity="error" // Since it's an error message
        >
          {error}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default SpeechForm;
