import callApi, { ContentType } from "./callApi"; // ensure the path is correct
import i18n from "../i18n";
const DEFAULT_SPEECH_TIME = 3000;
const DEFAULT_LANGUAGE = i18n.language;

export default function speechToText(
  time = DEFAULT_SPEECH_TIME,
  language = DEFAULT_LANGUAGE
) {
  return new Promise((resolve, reject) => {
    const AUDIO_CHUNK_DURATION_MS = 5000;
    let audioChunks = [];

    function handleSuccess(stream) {
      const options = MediaRecorder.isTypeSupported("audio/ogg;codecs=opus")
        ? { mimeType: "audio/ogg;codecs=opus" }
        : {};

      const mediaRecorder = new MediaRecorder(stream, options);
      mediaRecorder.start(AUDIO_CHUNK_DURATION_MS);

      mediaRecorder.addEventListener("dataavailable", (e) => {
        audioChunks.push(e.data);
      });

      mediaRecorder.addEventListener("stop", async () => {
        const audioBlob = new Blob(audioChunks, {
          type: "audio/ogg;codecs=opus",
        });

        try {
          const response = await callApi(
            "POST",
            `speech2text?language=${language}`,
            audioBlob,
            ContentType.OCTET_STREAM
          );

          if (response && response.data) {
            resolve(response.data.text);
          } else {
            reject("API call failed: Unknown error");
          }
        } catch (error) {
          reject(`API call failed: ${error}`);
        }
      });

      setTimeout(() => {
        mediaRecorder.stop();
      }, time);
    }

    function handleError(err) {
      reject(`Microphone access was denied: ${err}`);
    }

    navigator.mediaDevices
      .getUserMedia({ audio: true, video: false })
      .then(handleSuccess)
      .catch(handleError);
  });
}
