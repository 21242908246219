import {
  enumToString,
  stringToEnum,
} from "../../../../utils/typification/enums";

const TextType = {
  REGULAR: Symbol("regular"),
  ALPHANUMERIC: Symbol("ALPHANUMERIC"),
};

export function stringToComponentType(s) {
  try {
    return stringToEnum(s, TextType);
  } catch (err) {
    console.error(err);
    throw err; // Re-throwing the error to allow further handling by the caller
  }
}

export function componentTypeToString(c) {
  try {
    return enumToString(c, TextType);
  } catch (err) {
    console.error(err);
    throw err; // Re-throwing the error to allow further handling by the caller
  }
}

export default TextType;
