class ObjectError extends Error {
  constructor(message, obj) {
    super(message);
    this.name = this.constructor.name;
    this.obj = obj;
    Error.captureStackTrace(this, this.constructor);
  }
}

class RequestError extends Error {
  constructor(response) {
    super(response.message);
    this.name = this.constructor.name;
    this.response = response;
    Error.captureStackTrace(this, this.constructor);
  }
}

class IndistinguishableSpeechError extends Error {
  constructor(message = "Speech is indistinguishable") {
    super(message);
    this.name = this.constructor.name;
    Error.captureStackTrace(this, this.constructor);
  }
}

export { ObjectError, RequestError, IndistinguishableSpeechError };
