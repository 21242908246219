import FormComponentType from "../../components/forms/fields/typification/FormComponentType";
import { parse, format } from "date-fns";
import { ca } from "date-fns/locale";
import TextType from "../../components/forms/fields/typification/TextType";
import { normalizeString } from "../stringUtils";

const numbersMap = require("./numberMapping.json");
const specialCharsMap = require("./specialCharactersMapping.json");
const monthMap = require("./monthMapping.json");
const lettersMap = require("./letter-mapping.json");

const reverseLettersMap = Object.entries(lettersMap).reduce(
  (acc, [key, value]) => {
    for (let element of value) {
      acc[element] = key;
    }
    return acc;
  },
  {}
);

function getLongestMatch(string, mapping) {
  // Sort keys by length in descending order
  const keys = Object.keys(mapping).sort((a, b) => b.length - a.length);

  for (let key of keys) {
    if (string.includes(key)) {
      return string.replaceAll(key, mapping[key]);
    }
  }

  return string;
}

function replaceAllMatches(
  string,
  mapping,
  ignoreCase = true,
  wholeWord = false
) {
  // Sort keys by length in descending order
  const keys = Object.keys(mapping).sort((a, b) => b.length - a.length);
  keys.forEach((key) => {
    const regex = wholeWord ? `\\b${key}\\b` : key;
    if (ignoreCase) {
      string = string.replaceAll(new RegExp(regex, "gi"), mapping[key]);
    } else {
      string = string.replaceAll(new RegExp(regex, "g"), mapping[key]);
    }
  });
  return string;
}

function parseSpecialCharacters(text, wholeWord = false) {
  return replaceAllMatches(text, specialCharsMap, true, wholeWord);
}

export function parseNumberCaEs(text) {
  let number = getLongestMatch(text, numbersMap);
  if (!number) {
    number = text
      .split("-i-")
      .reduce((sum, num) => sum + (getLongestMatch(num, numbersMap) || 0), 0);
  }
  return parseInt(number);
}

function parseAllNumbers(text) {
  // Replace all numbers using a given mapping
  text = replaceAllMatches(text, numbersMap);

  // If there are no numbers, return the original text
  if (!text.match(/\d+/g) || text.match(/(\d+[a-z])|([a-z]\d+)+/gi)) {
    return text;
  }

  // Define a list of separators
  const separatorsList = ["-i-", " ", ",", ";"];

  // Create a regular expression to match any separator or number
  const separatorPattern = new RegExp(
    `(${separatorsList.join("|")}|\\d+)`,
    "g"
  );

  // Split the text by numbers and separators, keeping the separators
  let parts = text.split(separatorPattern);

  let sum = 0;
  let tempNumbers = [];
  let tempParts = [];

  // Iterate over parts and sum numbers if consecutive
  parts = parts.filter((part) => part !== "");
  parts.forEach((part, i) => {
    tempNumbers.push(part);
    tempParts.push(part);
    if (!separatorsList.includes(part) && !isNaN(part)) {
      if (i === parts.length - 1 || !separatorsList.includes(parts[i + 1])) {
        sum = tempNumbers.reduce((acc, curr) => acc + Number(curr), 0);
        let replaceRegex = new RegExp(tempParts.join("") + "(?=(\\s|$))");
        text = text.replace(replaceRegex, sum.toString());
        tempNumbers = [];
        tempParts = [];
        sum = 0;
      }
    }
  });

  return text;
}

function parseEmail(text) {
  text = parseSpecialCharacters(text);
  text = parseAllNumbers(text);
  return text.replaceAll(/\s+/g, "");
}

function parseDateCaEs(text, outputFormat = "yyyy-MM-dd") {
  for (const [catalan, monthNumber] of Object.entries(monthMap)) {
    text = text.replaceAll(new RegExp(catalan, "gi"), monthNumber);
  }
  let parts = text.split(/\s+del?\s+/);
  for (let i = 0; i < parts.length; i++) {
    parts[i] = parseAllNumbers(parts[i]);
  }
  console.log(parts);
  const date = parse(parts.join(" "), "dd MM yyyy", new Date(), {
    locale: ca,
  });
  return format(date, outputFormat);
}

function correctCommonMisspelledNumbers(text) {
  text = text.replaceAll(/hero/gi, "zero");
  return text;
}

function parseAllLetters(text) {
  text = normalizeString(text);
  text = replaceAllMatches(text, reverseLettersMap, true, true);
  return text;
}

export function parseAlphanumericCode(text) {
  text = parseSpecialCharacters(text, true);
  text = correctCommonMisspelledNumbers(text);
  text = parseAllLetters(text);
  text = text.split(/\s+/g).map(parseAllNumbers).join(" ");
  return text.replaceAll(/(\s|[,;.:])+/g, "").toUpperCase();
}

export default function extractAnswerFromTranscription(
  transcription,
  componentType,
  textType,
  language = "ca-ES"
) {
  if (!transcription) {
    return null;
  }

  switch (textType) {
    case TextType.ALPHANUMERIC:
      return parseAlphanumericCode(transcription);
    default:
      switch (componentType) {
        case FormComponentType.TEXT_AREA:
        case FormComponentType.TEXT_FIELD:
          return parseSpecialCharacters(transcription);

        case FormComponentType.NUMBER:
          return parseNumberCaEs(transcription);

        case FormComponentType.DATE:
          return parseDateCaEs(transcription);

        case FormComponentType.EMAIL:
          return parseEmail(transcription);
        default:
          return transcription;
      }
  }
}
