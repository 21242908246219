import React, { cloneElement, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Grid, Typography, Button, Snackbar } from "@material-ui/core";
import TitleCard from "./components/cards/TitleCard";
import { getSpeechText, readText } from "./utils/textToSpeech";
import speechToText from "./utils/speechToText";
import { isString } from "formik";
import { Alert } from "@material-ui/lab";
import { NavigationContext } from "./contexts/NavigationContext";
import { UserContext } from "./contexts/UserContext";
import { joinArrayElementsIntoStringSequence } from "./utils/speechUtils";
import useLocalStorage from "./hooks/useLocalStorage";
import { parseAlphanumericCode } from "./utils/processing/extractAnswerFromTranscription";
import TextType from "./components/forms/fields/typification/TextType";
import { getUserForms } from "./utils/apiMethods";
import { convertMultilingualPropertyToLanguage } from "./utils/formLanguageConversionUtils";

const HomePage = () => {
  const { handleNavigate } = useContext(NavigationContext);
  const { user } = useContext(UserContext);
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastSeverity, setToastSeverity] = useState("success");
  const [userData, setUserData] = useLocalStorage("userData", null);

  const { i18n, t } = useTranslation();

  const popToastMessage = (message, severity) => {
    setToastMessage(message);
    setToastSeverity(severity);
    setToastOpen(true);
  };

  const displaySpeechError = (text) => {
    popToastMessage(t("HOMEPAGE.DISPLAY.ERROR", { text: text }), "error");
  };

  const onCardClick = (templateFormId) => {
    handleNavigate(`/template/${templateFormId}`);
  };

  const [cards, setCards] = useState([
    <TitleCard
      id="A"
      title="Formulari Assistent de veu de registre de pesades de la verema"
      key={0}
      handleClick={() => {
        onCardClick("A");
      }}
      disabled={true}
    />,
    <TitleCard
      id="B"
      title="Formulari Assistent de veu de tràmits de la DUN"
      key={1}
      disabled={true}
    />,
  ]);

  const [enabledCardsIds, setEnabledCardsIds] = useState([]);

  useEffect(() => {
    if (!userData) {
      setEnabledCardsIds([]);
      return;
    }
    setEnabledCardsIds(userData.formIds);
  }, [userData]);

  useEffect(() => {
    const newCards = cards.map((card) => {
      const cardId = card.props.id;
      const enabled = enabledCardsIds.includes(cardId);
      return cloneElement(card, { disabled: !enabled });
    });
    setCards(newCards);
  }, [enabledCardsIds]);

  const getUserData = (userId) => {
    if (!userId) return null;
    if (!isString(userId)) return null;
    if (userId.length === 0) return null;
    if (userId === user.id) return { ...user, ...{ formIds: user.form_ids } };
  };

  // async function handleFormClick(...args) {
  //   if (!args || args.length === 0) return;
  //   let event = args[0];
  //   if (!event) return;
  //   let formId = event.currentTarget.id;
  //   console.debug("Form id: ", formId);
  //   if (formId) {
  //     await readText("Has seleccionat el formulari " + formId, displaySpeechError).catch((err)=>{console.error(err)});
  //     let card = getCardById(formId);
  //     console.debug("Card: ", card);
  //     if (card) {
  //       let handleClick = card.props.handleClick;
  //       if (handleClick) {
  //         handleClick();
  //         return;
  //       } else {
  //         console.debug("No handle click");
  //       }
  //     }
  //     await readText("No s'ha trobat cap acció per aquest formulari.", displaySpeechError).catch((err)=>{console.error(err)});
  //   }
  // }

  async function listenUserId() {
    let userId = await speechToText(3000, i18n.language).then((text) => {
      text = text.replace(/(hi){2,}/gi, "");
      text = text.trim();
      text = parseAlphanumericCode(text);
      return text;
    });
    return userId;
  }

  async function handleSpeechUserIdentification() {
    console.debug("Clicked start speech");
    await readText(t("VOICE.HOMEPAGE.ID.REQUEST"), displaySpeechError).catch(
      (err) => {
        console.error(err);
      }
    );
    let guessedUserId = null;
    try {
      guessedUserId = await listenUserId().catch(async (error) => {
        const errorMessage = t("VOICE.HOMEPAGE.ID.RESPONSE.INTERNAL_ERROR");
        popToastMessage(errorMessage, "error");
        await readText(errorMessage, displaySpeechError);
        console.error(error);
        throw error;
      });
    } catch (error) {
      console.error(error);
      return;
    }

    console.debug("User id: ", guessedUserId);
    if (!guessedUserId) {
      await readText(
        t("VOICE.HOMEPAGE.ID.RESPONSE.NOT_UNDERSTAND"),
        displaySpeechError
      ).catch((err) => {
        console.error(err);
      });
      return;
    }

    let newUserData = null;
    try {
      newUserData = getUserData(guessedUserId);
      if (!newUserData) {
        throw new Error("No s'ha trobat l'usuari.");
      }
    } catch (error) {
      console.error(error);
      const errorMessage = t("VOICE.HOMEPAGE.ID.RESPONSE.NOT_FOUND", {
        user_id: guessedUserId,
      });
      const readErrorMessage = t("VOICE.HOMEPAGE.ID.RESPONSE.NOT_FOUND", {
        user_id: getSpeechText(guessedUserId, TextType.ALPHANUMERIC),
      });

      popToastMessage(errorMessage, "error");
      await readText(readErrorMessage, displaySpeechError).catch((err) => {
        console.error(err);
      });
      return;
    }
    try {
      setUserData(newUserData);
      await readText(
        t("VOICE.HOMEPAGE.ID.RESPONSE.OK", {
          user_id: getSpeechText(newUserData.id, TextType.ALPHANUMERIC),
        }),
        displaySpeechError
      ).catch((err) => {
        console.error(err);
      });
      const userFormsData = await getUserForms(newUserData);
      if (userFormsData.length === 0) {
        await readText(
          t("VOICE.HOMEPAGE.FORMS.AVAILABLE.NO"),
          displaySpeechError
        ).catch((err) => {
          console.error(err);
        });
      } else if (userFormsData.length === 1) {
        await readText(
          t("VOICE.HOMEPAGE.FORMS.AVAILABLE.ONE", {
            form_name: convertMultilingualPropertyToLanguage(
              userFormsData[0].label,
              i18n.language
            ),
          }),
          displaySpeechError
        ).catch((err) => {
          console.error(err);
        });
      } else {
        const userFormsNames = userFormsData.map((formData) =>
          convertMultilingualPropertyToLanguage(formData.label, i18n.language)
        );

        const formLast = userFormsNames.pop();
        const formList = joinArrayElementsIntoStringSequence(
          userFormsNames,
          ", "
        );

        const textToRead = t("VOICE.HOMEPAGE.FORMS.AVAILABLE.MORE", {
          form_list: formList,
          form_last: formLast,
        });

        await readText(textToRead, displaySpeechError);
      }
    } catch (error) {
      console.error(error);
      await readText(
        t("VOICE.HOMEPAGE.ID.RESPONSE.ERROR", {
          user_id: getSpeechText(newUserData.id, TextType.ALPHANUMERIC),
        }),
        displaySpeechError
      ).catch((err) => {
        console.error(err);
      });
    }
  }

  // const getCardById = (id) => {
  //   if (!id) return;
  //   return cards.find((card) => {
  //     if (isString(card.props.id) && isString(id)) {
  //       return (
  //         card.props.id
  //           .toLowerCase()
  //           .replace(" ", "")
  //           .replace("formulari", "") ===
  //         id.toLowerCase().replace(" ", "").replace("formulari", "")
  //       );
  //     } else {
  //       return card.props.id === id;
  //     }
  //   });
  // };

  return (
    <Box className="componentContainer">
      <Grid
        container
        spacing={2}
        className="grid-container flex-center"
        // style={"flex-direction: vertical"}
      >
        <Typography variant="h2" className="homePage-title text-center">
          {t("HOMEPAGE.TITLE")}
        </Typography>
        <Grid item xs={12} className="text-center">
          <Button
            variant="contained"
            color="primary"
            className="main-button"
            onClick={handleSpeechUserIdentification}
            onKeyDown={handleSpeechUserIdentification}
          >
            {t("HOMEPAGE.BUTTON.START")}
          </Button>
          <Grid container spacing={2} className="grid-container flex-center">
            {cards.map((card, index) => (
              <Grid item key={index}>
                {card}
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <Snackbar
        open={toastOpen}
        autoHideDuration={6000}
        onClose={() => setToastOpen(false)}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={() => setToastOpen(false)}
          severity={toastSeverity}
        >
          {toastMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default HomePage;
