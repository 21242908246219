import React from "react";
import { Skeleton } from "antd";
import "../../App.css";
import RecorderStateType from "./typification/RecorderStateType";

const MultipleSkeleton = ({ count, active }) => {
  const skeletons = [];
  for (let i = 0; i < count; i++) {
    skeletons.push(<Skeleton key={i} active={active} />);
  }
  return <div className="skeleton-container">{skeletons}</div>;
};

const SkeletonComponent = ({ state }) => {
  const skeletonCount = 3;

  return (
    <MultipleSkeleton
      count={skeletonCount}
      active={state === RecorderStateType.RECORDING}
    />
  );
};

export default SkeletonComponent;
