import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { isSupportedLanguage } from "../i18n";

//components:
import { Dropdown } from "react-bootstrap";
import TranslateIcon from "@material-ui/icons/Translate";

//flags
import catalanFlag from "../assets/images/flag-catalonia.svg";
import spanishFlag from "../assets/images/flag-spain.svg";

const LanguageDropdown = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);

  const { t, i18n } = useTranslation();

  const handleSelect = (language) => {
    i18n.changeLanguage(language);
    setShowDropdown(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const getCurrentLanguage = () => {
    const detectedLang = i18n.language;
    const isSupported = isSupportedLanguage(detectedLang);

    return isSupported ? detectedLang : "ca-ES";
  };

  const flags = [
    {
      lang: "es-ES",
      name: t("LANGUAGE.DROPDOWN.ES"),
      svgUrl: (
        <img height={24} width={24} src={spanishFlag} alt={"Spanish Flag"} />
      ),
    },
    {
      lang: "ca-ES",
      name: t("LANGUAGE.DROPDOWN.CA"),
      svgUrl: (
        <img height={24} width={24} src={catalanFlag} alt={"Catalan Flag"} />
      ),
    },
  ];

  const sortedFlags = [...flags].sort((a, b) => {
    return a.name.localeCompare(b.name);
  });

  return (
    <Dropdown show={showDropdown} ref={dropdownRef}>
      <Dropdown.Toggle
        className="language_btn"
        onClick={() => setShowDropdown(!showDropdown)}
        onKeyDown={() => setShowDropdown(!showDropdown)}
      >
        <TranslateIcon className="language_icon" />
      </Dropdown.Toggle>

      {showDropdown && (
        <Dropdown.Menu className="language_dropdown-menu">
          {sortedFlags.map((flag) => {
            return (
              <Dropdown.Item
                key={flag.name}
                onClick={() => handleSelect(flag.lang)}
                onKeyDown={() => handleSelect(flag.lang)}
                active={getCurrentLanguage() === flag.lang}
              >
                <div className="language_dropdown-menu__item">
                  <div className="language_dropdown-menu__item__flag">
                    {flag.svgUrl}
                  </div>
                  <span className="language_dropdown-menu__item__name">
                    {flag.name}
                  </span>
                </div>
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      )}
    </Dropdown>
  );
};

export default LanguageDropdown;
