import React, { useContext } from "react";
import {
  Drawer,
  ClickAwayListener,
  List,
  ListItem,
  ListItemIcon,
  Divider,
} from "@material-ui/core";
import { NavigationContext } from "../../contexts/NavigationContext";
import FormsArchiveSection from "./FormsAchiveSection";
import { lateralMenuLogo } from "../../utils/lookAndFeel";

const LateralMenu = ({ onClose }) => {
  const { handleNavigate } = useContext(NavigationContext);

  const handleFormSelected = (formId) => {
    handleNavigate(`/form/${formId}`);
  };

  return (
    <ClickAwayListener onClickAway={onClose}>
      <Drawer
        variant="permanent"
        anchor="left"
        PaperProps={{
          className: "lateral-drawer",
        }}
      >
        <List>
          {lateralMenuLogo ? (
            <>
              <ListItem
                button
                onClick={() => handleNavigate("/")}
                onKeyDown={() => handleNavigate("/")}
              >
                <ListItemIcon>
                  <img
                    src={lateralMenuLogo}
                    alt="App logo"
                    className="lateral-logo"
                  />
                </ListItemIcon>
              </ListItem>
              <Divider />
            </>
          ) : null}
          <FormsArchiveSection onFormSelected={handleFormSelected} />
          <Divider />
          {/* ... other potential sections */}
        </List>
      </Drawer>
    </ClickAwayListener>
  );
};

export default LateralMenu;
