import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Button from "@material-ui/core/Button";
import { NavigationContext } from "../contexts/NavigationContext";
import { UserContext } from "../contexts/UserContext";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import LanguageDropdown from "./LanguageDropdown";
import { headerLogo } from "../utils/lookAndFeel";

const Header = () => {
  const { user, logout } = useContext(UserContext);
  const {
    currentPath,
    handleNavigate,
    setIsLateralMenuOpen,
    isLateralMenuOpen,
  } = useContext(NavigationContext);

  const { t } = useTranslation();

  const toggleLateralMenu = () => {
    setIsLateralMenuOpen(!isLateralMenuOpen);
  };

  const handleLogoClick = () => {
    handleNavigate("/");
  };

  return (
    <AppBar position="fixed" color="inherit">
      <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
        <div className="flex-center">
          <IconButton edge="start" color="inherit" onClick={toggleLateralMenu}>
            {currentPath !== "/login" ? <MenuIcon /> : null}
          </IconButton>
          {headerLogo ? (
            <img
              alt="App logo"
              className="header-logo"
              onClick={handleLogoClick}
              onKeyDown={handleLogoClick}
              src={headerLogo}
            />
          ) : null}
        </div>
        <div className="flex-center">
          <div className="header-options">
            {currentPath.includes("/template/") ? null : <LanguageDropdown />}
            {user ? (
              <>
                <Typography variant="subtitle1" sx={{ marginRight: 1 }}>
                  {user.name}
                </Typography>
                <Button
                  color="inherit"
                  onClick={logout}
                  onKeyDown={logout}
                  startIcon={<ExitToAppIcon />}
                >
                  {t("HOMEPAGE.BUTTON.LOGOUT")}
                </Button>
              </>
            ) : null}
          </div>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
